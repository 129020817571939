import Image from 'next/image';
import React, { useState } from 'react';
import { FooterItem } from './FooterItem';
import { DownloadAppStore, DownloadGGPlay, LogoGamWhite, LogoGamWhiteLarge } from '@/components/icons';
import Link from 'next/link';
import ChatCSKH from '@/modules/public-modules/login/components/ChatCSKH';

const DOWNLOAD_APP = {
  ios: 'https://apps.apple.com/us/app/apollo-giải-pháp-ứng-dụng-ai/id6740890777',
  android: 'https://play.google.com/store/apps/details?id=vn.gam.apollo',
};

const FLink = ({ href, children }) => (
  <Link href={href} className="text-th-background font-normal">
    {children}
  </Link>
);

export const Footer = () => {
  const [open, setOpen] = useState(false);

  return (
    <footer className="relative">
      <Image
        src="/imgs/landing_img.png"
        fill
        alt="Landing image"
        className="absolute object-cover inset-0 z-0"
      />

      <div className="w-[90%] 2xl:w-[70%] mx-auto py-10">
        <div className="flex flex-col lg:flex-row gap-8 justify-between items-start lg:items-center  relative z-10 text-th-background text-sm">
          <Link href="/">
            <LogoGamWhiteLarge className="hidden lg:block" />
            <LogoGamWhite className="lg:hidden block" />
          </Link>
          <div className="flex flex-col items-start gap-2">
            <a href={DOWNLOAD_APP.ios} target="_blank" rel="noreferrer">
              <DownloadAppStore />
            </a>
            <a href={DOWNLOAD_APP.android} target="_blank" rel="noreferrer">
              <DownloadGGPlay />
            </a>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-start gap-8 lg:gap-16 text-th-background relative z-10 py-16 text-xs xl:text-base">
          <div className="flex gap-16 shrink-0 h-full">
            <FooterItem title="Về Apollo">
              <div className="flex flex-col items-start gap-2">
                <button>Về chúng tôi</button>
                <button>Đối tác</button>
                <button>Liên hệ</button>
              </div>
            </FooterItem>

            <FooterItem title="Giải pháp">
              <div className="flex flex-col items-start gap-2">
                <button>Tự động hóa</button>
                <button className="text-left">Nâng cao trải nghiệm KH</button>
                <button>Cá nhân hóa</button>
              </div>
            </FooterItem>
          </div>

          <FooterItem title="Tin tức & Sự kiện">
            <div className="flex flex-col items-start gap-2">
              <FLink href="/news">Tin tức</FLink>
              <button>Sự kiện</button>
              <button className="text-left">Câu chuyện doanh nghiệp</button>
            </div>
          </FooterItem>

          <FooterItem title="Hỗ trợ">
            <div className="flex flex-col items-start gap-2">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-th-background font-normal"
                href="https://gam.vn/privacy-policy-web"
              >
                Chính sách bảo mật
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-th-background font-normal"
                href="https://gam.vn/terms-conditions-web"
              >
                Điều khoản sử dụng
              </a>
              <button>Kết nối API</button>
            </div>
          </FooterItem>

          <FooterItem title="Liên hệ">
            <div className="flex flex-col items-start gap-2 font-normal">
              <div>Hotline: 02466519203</div>
              <div>Email: info@gam.vn</div>
              <div>
                Địa chỉ: Số 185 phố Chùa Láng, Phường <br /> Láng Thượng, Quận Đống Đa, Thành phố Hà Nội.
              </div>
              <div>
                Cần hỗ trợ:{' '}
                <button className="text-th-primary-alt" onClick={() => setOpen(true)}>
                  Chat ngay
                </button>
              </div>
            </div>
          </FooterItem>
        </div>

        <div className="w-full h-[1px] bg-th-gray-700 relative z-10 mb-10" />

        <div className="flex flex-col lg:flex-row justify-between lg:items-center text-th-background relative z-10 text-sm gap-10 lg:gap-20 text-xs xl:text-base">
          <div>
            CÔNG TY TNHH <br /> GIẢI PHÁP CÔNG NGHỆ DỮ LIỆU GAM
            <div className="lg:block hidden">Copyright © 2025 GAM Solutions. All Rights Reserved.</div>
          </div>
          <div>
            <div>Giấy CNĐKKD: 0110865119 - Ngày cấp 16/10/2024</div>
            <div>Đăng ký kinh doanh thay đổi lần thứ 1: ngày 16 tháng 12 năm 2024</div>
            <div>Cơ quan cấp: Phòng Đăng ký kinh doanh - Sở kế hoạch và đầu tư TP Hà Nội </div>
          </div>
          <div className="block lg:hidden">Copyright © 2025 GAM Solutions. All Rights Reserved.</div>
        </div>
      </div>
      <ChatCSKH openChat={open} setOpenChat={setOpen} />
    </footer>
  );
};
