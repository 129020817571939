import React, { useEffect, useState } from 'react';
import { ArrowLeftAlt, LogoGamWhite } from '../../icons';
import Link from 'next/link';
import { NavMobile } from '@/modules/landingPage/components/NavMobile';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { motion } from 'framer-motion';

export const LandingpageHeader = () => {
  const router = useRouter();
  const currentRoute = router.pathname;
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeScrolled = window.scrollY > 0;
      setIsScrolled(shouldBeScrolled);
    };

    handleScroll(); // Call once to set initial state
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cn('sticky top-0 left-0 right-0 z-50 transition-all ', {
        'shadow-md backdrop-blur-xl': isScrolled,
      })}
    >
      <motion.div
        initial={false}
        animate={{ opacity: !isScrolled ? 0 : '60%' }}
        className="absolute inset-0 bg-th-text w-full z-10"
      />
      <div
        className={cn(
          'flex justify-between items-center text-th-background w-[90%] xl:w-[75%] 2xl:w-[60%] mx-auto pt-4 pb-3 relative z-20'
        )}
      >
        <Link href="/">
          <LogoGamWhite />
        </Link>
        <div className="hidden lg:flex gap-8 border border-th-background rounded-full px-8 py-3 bg-th-gray-500 ">
          <Link
            href="/"
            className={cn('text-th-gray-300', {
              '!text-th-background font-semibold': currentRoute === '/',
            })}
          >
            Trang chủ
          </Link>
          <Link
            href="#"
            className={cn('text-th-gray-300', {
              '!text-th-background font-semibold': currentRoute === '/about-us',
            })}
          >
            Về chúng tôi
          </Link>
          <Link
            href="/solutions"
            className={cn('text-th-gray-300', {
              '!text-th-background font-semibold': currentRoute === '/solutions',
            })}
          >
            Giải pháp
          </Link>
          <Link
            href="/news"
            className={cn('text-th-gray-300', {
              '!text-th-background font-semibold': ['/news', '/news/[id]'].includes(currentRoute),
            })}
          >
            Tin tức
          </Link>
        </div>
        <Link
          href="/login"
          className="hidden lg:flex gap-2 items-center px-8 py-2 bg-th-background text-th-text rounded-full font-semibold"
        >
          Đăng nhập <ArrowLeftAlt className="rotate-180 scale-75" />
        </Link>

        <NavMobile />
      </div>
    </div>
  );
};
