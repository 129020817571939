import cn from 'classnames';
interface Props {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

export const FooterItem: React.FC<Props> = ({ children, title, className }) => {
  return (
    <div className={cn('flex flex-col gap-4 font-semibold justify-between', className)}>
      <div className="text-th-primary-alt">{title}</div>
      <div>{children}</div>
    </div>
  );
};
