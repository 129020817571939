import React, { useEffect, useState, useRef } from 'react';
import { motion, useCycle } from 'motion/react';
import { useDimensions } from '@/hooks/useDimensions';
import cn from 'classnames';
import Link from 'next/link';
import { createPortal } from 'react-dom';

interface IProps {
  toggle: () => void;
  isOpen: boolean;
}

const sidebar = {
  open: (height = 800) => ({
    clipPath: `circle(${height * 2 + 200}px at 100% 0px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 100% 0px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const variantsNav = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const NAVS = [
  {
    label: 'Trang chủ',
    href: '/',
  },
  {
    label: 'Về chúng tôi',
    href: '#',
  },
  {
    label: 'Giải pháp',
    href: '/solutions',
  },
  {
    label: 'Tin tức',
    href: '/news',
  },
];

export const NavMobile = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [shouldPreserveHeight, setShouldPreserveHeight] = useState(false);
  const containerRef = useRef<HTMLElement>(null);
  const { height } = useDimensions(containerRef as React.MutableRefObject<HTMLElement>);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;
    const root = document.documentElement;

    if (!isOpen) {
      root.style.overflow = 'auto';
      id = setTimeout(() => setShouldPreserveHeight(false), 1000);
    } else {
      // Disable scroll when menu is open
      root.style.overflow = 'hidden';
      setShouldPreserveHeight(true);
    }

    return () => clearTimeout(id);
  }, [isOpen]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  return (
    <div className="lg:hidden flex items-center h-full mb-2">
      {createPortal(
        <>
          <motion.nav
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            custom={height}
            ref={containerRef}
            className={cn('fixed top-0 right-0 z-[100] block lg:hidden', {
              'w-full h-full': shouldPreserveHeight,
            })}
          >
            <motion.div
              className="absolute inset-0 backdrop-brightness-[60%] backdrop-blur-xl w-full z-[100]"
              variants={sidebar}
            />
            <div className="absolute left-0 w-full h-[80%] bottom-10 z-[101] flex flex-col justify-between">
              <motion.ul
                variants={variantsNav}
                className={cn('flex flex-col items-center w-full gap-7', {
                  hidden: !shouldPreserveHeight,
                })}
              >
                {NAVS.map((nav) => (
                  <MenuItem
                    nav={nav}
                    key={nav.label}
                    onClick={() => {
                      toggleOpen();
                    }}
                  />
                ))}
              </motion.ul>
            </div>
            <MenuToggle
              isOpen={isOpen}
              toggle={() => {
                toggleOpen();
              }}
            />
          </motion.nav>
        </>,
        document.body
      )}
    </div>
  );
};

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    strokeLinecap="round"
    className="stroke-th-gray-50"
    {...props}
  />
);

const MenuToggle: React.FC<IProps> = ({ toggle, isOpen }) => (
  <button
    onClick={toggle}
    className="z-[101] w-[42px] h-[42px] absolute right-4 top-4 bg-th-gray-700 flex items-center justify-center rounded-lg pt-1"
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        animate={{
          d: isOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5',
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        animate={{
          opacity: isOpen ? 0 : 1,
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        animate={{
          d: isOpen ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 20 16.346',
        }}
      />
    </svg>
  </button>
);

interface IPropMenuItem {
  nav: {
    label: string;
    href: string;
  };
  onClick?: () => void;
}

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        type: 'spring',
        stiffness: 300,
        damping: 40,
      },
    },
  },
  closed: {
    x: -100,
    opacity: 0,
    transition: {
      x: {
        type: 'spring',
        damping: 3,
        stiffness: 50,
      },
    },
  },
};

export const MenuItem: React.FC<IPropMenuItem> = ({ nav, onClick }) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className="list-none"
    >
      <Link className="text-th-background text-xl block" href={nav.href}>
        {nav.label}
      </Link>
    </motion.li>
  );
};
